.vm-list {
    padding: 15px;
}

.vm-box {
    margin: 5px;
    border: solid 2px black;
    border-radius: 5px;
}

.vm-box:not(:last-child) {
    margin-bottom: 25px;
}

.vm-box-header {
    display: flex;
    justify-content: space-between;
}

.vm-box-header > span {
    display: flex;
    padding: 5px;
    line-height: 1;
    border-bottom: solid 2px black;
    border-right: solid 2px black;
}

.vm-box-header > span:first-child {
    border-top-left-radius: 5px;
}

.vm-box-header > span:last-child {
    border-top-right-radius: 5px;
    border-right: none;
}

.vm-box-header > .vm-name {
    flex-grow: 1;
    text-align: center;
    display: block;
}

.vm-box-header > .vm-hostname {
    user-select: text;
    background-color: #cfcfcf;
}

.vm-info {
    display: flex;
    padding: 15px;
    flex-direction: row;
}

.vm-info .vm-property:not(:last-child) {
    margin-bottom: 10px;
}

.vm-info span {
    font-weight: 500;
}

.vm-info .chip {
    font-family: monospace;
    display: inline-block;
    background-color: #cfcfcf;
    padding: 3px 5px 3px 5px;
    margin-left: 10px;
    border-radius: 5px;
}

.vm-info > * {
    flex: 1 1 0;
    flex-direction: column;
    display: flex;
}

.vm-volumes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vm-volumes-list {
    border: 2px solid black;
    border-radius: 5px;
    margin-top: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    overflow-y: auto;
}

.vm-volume {
    display: flex;
    align-items: center;
    padding: 5px;
}

.vm-console-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.vm-controls {
    margin: 10px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vm-controls span {
    display: flex;
}

.vm-console-view .vm-hostname {
    user-select: text;
    background-color: #cfcfcf;
    border: solid 1px black;
    padding: 5px;
    display: flex;
    line-height: 1;
}

.vm-controls input {
    text-align: center;
    margin-right: 8px;
}

.vm-controls input:focus {
    outline: none;
}

.vm-controls button {
    margin-left: 5px;
    margin-right: 5px;
}

.vm-console {
    position: relative;
    background-color: #000000;
    margin: 10px;
    flex-grow: 1;
}

.status {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}
