#login-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

#login-view {
    padding: 120px 200px;
    background-color: white;
    border-radius: 15px;
    text-align: center;
}

#login-view .logo {
    width: 100px;
    aspect-ratio: 1;
}

#login-view button {
    font-size: 12pt;
    display: block;
    padding: 6px 25px;
    background-color: white;
    border: solid 1px black;
    border-radius: 3px;
    margin: 30px auto 0 auto;
    transition: all 0.2s;
}

#login-view button:hover {
    background-color: #e1e1e1;
}
