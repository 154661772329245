@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body, html, #root, #desktop {
    width: 100%;
    height: 100%;
}

body {
    background-color: black;
    font-family: "Roboto", sans-serif;
    user-select: none;
}

#desktop {
    background-image: url("/src/img/logo-full.webp");
    background-repeat: no-repeat;
    background-position: 95% 95%;
    background-size: 20%;
}

.clearfix {
    float: none;
}

.window {
    box-sizing: content-box;
    position: absolute;
    border: 5px solid #f1f1f1;
    border-radius: 4px 4px 0 0;
    background: white;
    resize: both;
    overflow: hidden;
    min-width: 650px;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.window-header {
    position: relative;
    padding: 6px;
    background: #f1f1f1;
    border-radius: inherit;
}

.window-button {
    margin-top: 2px;
    margin-right: 7px;
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #bbb;
    display: inline-block;
    cursor: initial;
    transition: all 0.2s;
}

.window-button:hover {
    filter: brightness(1.2);
}

.window-title {
    position: absolute;
    user-select: none;
    left: 75px;
    right: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

.window-content {
    overflow: auto;
    height: 100%;
}

#search-bar {
    border-radius: 16px;
    background: rgba(255, 255, 255, 75%);
    position: absolute;
    width: 30%;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    transition: all 0.3s;
    z-index: 1000;
}

#search-bar > input {
    font-size: 15pt;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding: 20px;
}

.invisible {
    opacity: 0 !important;
    pointer-events: none;
}

* > svg {
    margin-right: 5px;
}

button {
    display: inline-flex;
    line-height: 1;
    padding: 5px;
}

@keyframes full-screen {
    to { left: 0; top: 0; width: 100%; height: 100%; border: none; border-radius: 0; }
}

@keyframes windowed {
    from { left: 0; top: 0; width: 100%; height: 100%; border: none; border-radius: 0; }
}
